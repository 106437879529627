import React from 'react'
import Swal from 'sweetalert2'
import { AddNewStatement } from '../../api/Api'
import { Form, InputGroup, Modal, Button } from 'react-bootstrap'
import './RegistrationStatement.css'
import FileUploader from '../../controls/fileUploader/fileUploader'
import { Suladobis_koreqtireba, Koreqcia, registration, Shewyveta } from '../../constants'
import Preamble from "../preamble/Preamble"
import { Loader } from '../loader/Loader'
import Recaptcha from 'react-recaptcha' 
import { componentsToColor } from 'pdf-lib'

export default class RegistrationStatement extends React.Component {
    state = {
        isVerified: false,
        accept: true,
        number: this.props.statement ? this.props.statement.number : '',
        firstName: this.props.statement ? this.props.statement.firstName : '',
        lastName: this.props.statement ? this.props.statement.lastName : '',
        phoneNumber: this.props.statement ? this.props.statement.phoneNumber : '',
        mail: this.props.statement ? this.props.statement.mail : '',
        identityCode: this.props.statement ? this.props.statement.identityCode : '',
        oName: this.props.statement ? this.props.statement.oName : '',
        privateNumber: this.props.statement ? this.props.statement.privateNumber : '',
        address: this.props.statement ? this.props.statement.address : '',
        contractorType: this.props.statement ? this.props.statement.contractorType : 0,
        contractType: this.props.statement ? this.props.statement.contractType : 0,
        description: this.props.statement ? this.props.statement.description : '',
        files: this.props.statement ? this.props.statement.files : '',
        recaptchaResponse: '',
        buttonEnabled: true,
        showValidationModal: false
    }

    handleChange = (event) => {
        const { name, value } = event.target
        if(name === 'firstName' || name === 'lastName' || name === 'address'){ 
            if (!/[^ა-ჰ0-9- ,]/.test(value) ) { 
            this.setState({ [name]: value.replace(/[^ა-ჰ0-9- ,]/gi, "") }, () => {
                this.validateForm();
                if (name === 'contractType') {
                    this.InfoPack();
                }
            })}
            else{
                Swal.fire('შეავსეთ ქართულად', '', 'warning')
            }
        }
        else{
            this.setState({ [name]: value }, () => {
                this.validateForm();
                if (name === 'contractType') {
                    this.InfoPack();
                }
            })
        }
        

    }
    //info Pack
    InfoPack = () => {
        if (this.state.contractorType === '1') {
            if (this.state.contractType === '1') {
                Swal.fire({ html: Suladobis_koreqtireba })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნულ აბონენტზე განახორციელოთ დასუფთავების მოსაკრებლის კორექტირება სულადობრივი ცნობის შესაბამისად.' })

            } else if (this.state.contractType === '2') {
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნულ აბონენტს დაარიცხოთ დასუფთავების მოსაკრებელი, სოციალური შეღავათის გათვალისწინებით.' })
            }
        }
        if (this.state.contractorType === '2') {
            if (this.state.contractType === '3') {
                Swal.fire({ html: registration })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნული აბონენტი დაარეგისტრიროთ დასუფთავების მოსაკრებლის გადამხდელად.' })
            } else if (this.state.contractType === '4') {
                Swal.fire({ html: Koreqcia })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნული აბონენტზე განახორციელოთ დასუფთავების მოსაკრებლის კორექტირება.' })
            } else if (this.state.contractType === '5') {
                Swal.fire({ html: Shewyveta })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნულ აბონენტზე შეწყვიტოთ დასუფთავების მოსაკრებლის დარიცხვა.' })
            }
        }
        if (this.state.contractorType === '3') {
            if (this.state.contractType === '3') {
                Swal.fire({ html: registration })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნული აბონენტი დაარეგისტრიროთ დასუფთავების მოსაკრებლის გადამხდელად.' })
            } else if (this.state.contractType === '4') {
                Swal.fire({ html: Koreqcia })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნული აბონენტზე განახორციელოთ დასუფთავების მოსაკრებლის კორექტირება.' })
            } else if (this.state.contractType === '5') {
                Swal.fire({ html: Shewyveta })
                this.setState({ description: 'გთხოვთ, ზემოაღნიშნულ აბონენტზე შეწყვიტოთ დასუფთავების მოსაკრებლის დარიცხვა.' })
            }
        }

    }
    //ფაილების ატვირთვა 
    handleFiles = (files) => {
        this.setState({ files: files })
    }

    recaptchaLoader = () => {
    }
    recaptchaverifyCallback = (response) => {
        if (response) {
            this.setState({ isVerified: true, recaptchaResponse: response })
        }
    }

    validateForm = () => {
        const { firstName, lastName, phoneNumber, contractorType, contractType, privateNumber, number, address, description, isVerified } = this.state
        return (
            isVerified &&
            number.length > 0 &&
            privateNumber.length === 11 &&
            firstName.length > 0 &&
            lastName.length > 0 &&
            phoneNumber.length === 9 &&
            contractorType > 0 &&
            contractType > 0 &&
            address.length > 0 
        )
    }
    handleViewDoc = () => {
        // if (this.state.contractorType === '1') {
        //     if (this.state.contractType === '1') {
        //         this.setState({ description: 'გთხოვთ, აბ.' + this.state.number + '-ზე განახორციელოთ სულადობის კორექტირება, წარმოდგენილი დოკუმენტაციის შესაბამისად.' }, () => { this.props.getStatement(this.state) })
        //     } else if (this.state.contractType === '2') {
        //         this.setState({ description: 'გთხოვთ, წარმოდგენილი დოკუმენტაციის შესაბამისად, აბ.' + this.state.number + '-ზე დაარიცხოთ დასუფთავების მოსაკრებელი, სოციალური შეღავათის გათვალისწინებით.' }, () => { this.props.getStatement(this.state) })
        //     }
        // } else {
        this.props.getStatement(this.state)
        // }
    }

    handleRegister = async () => {
        //  const res  =  await AddNewStatement(this.state);  
        //    Swal.fire({
        //         title: 'გსურთ განცხადების დარეგისტრირება?',
        //         showDenyButton: true,
        //         confirmButtonText: `შენახვა`,
        //         denyButtonText: `გაუქმება`,
        //     }).then((result) => {

        await this.setState({ buttonEnabled: false });
        const res = await AddNewStatement(this.state);
        await this.setState({ buttonEnabled: true });
        const { success, errors } = res;
        if (success) {
            Swal.fire('განცხადება წარმატებით დარეგისტრირდა!', '', 'success')
            // this.handleClearForm();
        } else {
            let errtext = "";
            if (errors) errors.map((item) => errtext += item + '\n')
            Swal.fire(errtext, '', 'info')
        }
    }

    handleClearForm = () => {
        this.setState({
            accept: true,
            isVerified: false,
            number: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            mail: '',
            identityCode: '',
            oName: '',
            privateNumber: '',
            address: '',
            contractorType: 0,
            contractType: 0,
            description: '',
            file: ''
        })
    }
    render() {
        const {
            number,
            firstName,
            lastName,
            privateNumber,
            phoneNumber,
            mail,
            identityCode,
            oName,
            contractorType,
            contractType,
            address,
            files,
            description,
            buttonEnabled
        } = this.state
        return (
            <>
                <div>
                    <h4>დასუფთავების მოსაკრებლის სარეგისტრაციო განაცხადი</h4>
                    <hr />
                    <Form
                        noValidate
                        validated={this.validateForm()}
                    // onSubmit={this.handleRegister}
                    >

                        <Form.Group controlId="validationForm1">
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                აირჩიე პირი
                            </Form.Label>
                            <div className="form-group">
                                <select
                                    className="custom-select"
                                    name="contractorType"
                                    value={contractorType}
                                    onChange={this.handleChange}
                                >

                                    <option value={0}>აირჩიე პირი</option>
                                    <option value={1}>ფიზიკური პირი</option>
                                    <option value={2}>იურიდიული პირი</option>
                                    <option value={3}>ინდივიდუალური მეწარმე</option>
                                </select>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="validationForm2">
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                აირჩიეთ მოქმედება
                            </Form.Label>
                            <div className="form-group">
                                <select
                                    className="custom-select"
                                    name="contractType"
                                    value={contractType}
                                    onChange={this.handleChange}
                                >
                                    {this.state.contractorType === '1' ?
                                        <>
                                            <option value={0}>აირჩიეთ მოქმედება</option>
                                            <option value={1}>სულადობის კორექტირება</option>
                                            {/* <option value={2}>სოციალური შეღავათი</option> */}

                                        </> :
                                        this.state.contractorType === '2' ?
                                            <>
                                                <option value={0}>აირჩიეთ მოქმედება</option>
                                                <option value={3}>რეგისტრაცია</option>
                                                <option value={4}>კორექცია</option>
                                                <option value={5}>შეწყვეტა</option>
                                            </>
                                            : this.state.contractorType === '3' ?
                                                <>
                                                    <option value={0}>აირჩიეთ მოქმედება</option>
                                                    <option value={3}>რეგისტრაცია</option>
                                                    <option value={4}>კორექცია</option>
                                                    <option value={5}>შეწყვეტა</option>
                                                </>
                                                : <>
                                                    <option value={0}>აირჩიეთ მოქმედება</option>
                                                </>
                                    }
                                </select>
                            </div>
                        </Form.Group>

                        <Form.Group controlId='validationForm12'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                აბონენტის ნომერი
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    autoComplete='off'
                                    required
                                    type='number'
                                    name='number'
                                    placeholder='აბონენტის ნომერი'
                                    value={this.state.number}
                                    onChange={this.handleChange}
                                    isValid={number.length > 0}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    გთხოვთ შეავსეთ აბონენტის ნომერი  !
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        {contractorType === '2' || contractorType === '3' ? (
                            <>
                                <Form.Group controlId='validationForm3'>
                                    <span className='necessey-field'>*</span>
                                    <Form.Label className='font-weight-bold'>
                                        საიდენტიფიკაციო ნომერი
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            autoComplete='off'
                                            required
                                            type='number'
                                            name='identityCode'
                                            placeholder='საიდენტიფიკაციო ნომერი'
                                            value={this.state.identityCode}
                                            onChange={this.handleChange} 
                                            isValid={identityCode.length > 8}
                                        />

                                        <Form.Control.Feedback type='invalid'>
                                            საიდენტიფიკაციო კოდი შეავსეთ კორექტულად!
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId='validationForm4'>
                                    <span className='necessey-field'>*</span>
                                    <Form.Label className='font-weight-bold'>
                                        ორგანიზაციის დასახელება {contractorType === '3' ? <>( საფირმო სახელი )</> : <></>}
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            autoComplete='off'
                                            required
                                            type='text'
                                            name='oName'
                                            placeholder='ორგანიზაციის დასახელება'
                                            value={this.state.oName}
                                            onChange={this.handleChange}
                                            isValid={oName.length > 0}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            ორგანიზაციის დასახელება შეავსეთ კორექტულად!
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </>
                        )
                            : (<div></div>)
                        }

                        <Form.Group controlId='validationForm5'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                სახელი
                            </Form.Label>
                            <Form.Control
                                autoComplete='off'
                                required
                                type='text'
                                name='firstName'
                                placeholder='სახელი'
                                value={firstName}
                                onChange={this.handleChange}
                                isValid={firstName.length > 0}
                            />
                            <Form.Control.Feedback type='invalid'>
                                გთხოვთ შეავსოთ სახელი
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='validationForm6'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                გვარი
                            </Form.Label>
                            <Form.Control
                                autoComplete='off'
                                required
                                type='text'
                                name='lastName'
                                placeholder='გვარი'
                                value={lastName}
                                onChange={this.handleChange}
                                isValid={lastName.length > 0}
                            />
                            <Form.Control.Feedback type='invalid'>
                                გთხოვთ შეავსოთ გვარი
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='validationForm7'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                პირადი ნომერი
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    autoComplete='off'
                                    required
                                    type='number'
                                    name='privateNumber'
                                    placeholder='პირადი ნომერი'
                                    value={this.state.privateNumber}
                                    onChange={this.handleChange}
                                    isInvalid={privateNumber.length !== 11 && privateNumber.length > 0}
                                    isValid={privateNumber.length === 11}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    პირადი ნომერი შეავსეთ კორექტულად!
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId='validationForm8'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                მობილური ტელეფონი
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    autoComplete='off'
                                    required
                                    type='number'
                                    name='phoneNumber'
                                    placeholder='მობილური ტელეფონი'
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChange}
                                    isInvalid={phoneNumber.length !== 9 && phoneNumber.length > 0}
                                    isValid={phoneNumber.length === 9}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    მობილური ტელეფონის შეავსეთ კორექტულად!
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId='validationForm9'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                ელ-ფოსტა
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    autoComplete='off'
                                    required
                                    type='email'
                                    name='mail'
                                    placeholder='ელ-ფოსტა'
                                    value={mail}
                                    onChange={this.handleChange}
                                    isValid={mail.includes('@')}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId='validationForm10'>
                            <span className='necessey-field'>*</span>
                            <Form.Label className='font-weight-bold'>
                                {this.state.contractorType === '2' ? <>ორგანიზაციის მისამართი</> : <>მისამართი</>}
                            </Form.Label>
                            <Form.Control
                                autoComplete='off'
                                required
                                type='text'
                                name='address'
                                placeholder='მისამართი'
                                value={address}
                                onChange={this.handleChange}
                                isValid={address.length > 0}
                            />
                            <Form.Control.Feedback type='invalid'>
                                გთხოვთ შეავსოთ მისამართი
                            </Form.Control.Feedback>
                        </Form.Group>
                        {contractorType === '2' || contractorType === '3' ? (
                            <>
                                <Form.Group controlId='validationForm11'>
                                    <span className='necessey-field'>*</span>
                                    <Form.Label className='font-weight-bold'>
                                        განცხადების შინაარსი
                                    </Form.Label>

                                    <textarea className="form-control" autoComplete='off'
                                        required
                                        type='textarea'
                                        name='description'
                                        placeholder='განცხადების შინაარსი'
                                        value={description}
                                        onChange={this.handleChange}
                                        rows="3" />

                                    <Form.Control.Feedback type='invalid'>
                                        გთხოვთ შეავსოთ ტექსტი
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>) : (<div></div>)}
                        <Form.Group controlId='validationForm16'>
                            <Form.Label className='font-weight-bold'>
                                ატვირთეთ ფაილი
                            </Form.Label>
                            <FileUploader getFiles={this.handleFiles} files={this.state.files} />
                            <Form.Control.Feedback type='invalid'>
                                ატვირთული ფაილის ზომა არ უნდა აღემატებოდეს 2 MB ს
                            </Form.Control.Feedback>
                        </Form.Group> 
                        <Recaptcha
                            sitekey="6Lee4NQaAAAAAKLPgJfKh9YLE8zmm0WaJih0XXqY"
                            render="explicit"
                            hl='ka'
                            onloadCallback={this.recaptchaLoader}
                            verifyCallback={this.recaptchaverifyCallback}
                        />
                        <hr />
                        <div className="form-group">
                            <div
                                disabled={!this.validateForm()}
                                type="button"
                                className="btn btn-success btn-block"
                                //onClick={this.handleRegister}
                                onClick={this.handleViewDoc}
                            >
                                {!buttonEnabled ? (
                                    <Loader />
                                ) : (
                                    <>დოკუმენტის ნახვა</>
                                )} </div>
                        </div>
                    </Form>

                </div>

                {/* </div> */}
            </>
        )
    }
}

