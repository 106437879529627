import React from 'react'; 
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel'; 
import Typography from '@material-ui/core/Typography';
import RegistrationStatement from "../registrationStatement/RegistrationStatement"
import DocView from "../docView/DocView"
import Preamble from "../preamble/Preamble"
import "./StatementStepper.css" 


 
export default class StatementStepperClass extends React.Component {
    state = {
        activeStep: 0,
        accept: true,
        steps: ['განაცხადის შევსება ', 'დოკუმენტი'], //, 'დასრულება'
        classes: '',
        statementInfo: '',
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <RegistrationStatement 
                getStatement={this.handleStatement}
                statement={this.state.statementInfo} 
                 />;
            case 1:
                return <DocView

                    setStatement={this.state.statementInfo}
                    backToreg={this.handleBack} 
                    />;
            case 2:
                return 'This is the bit I really care about!';
            default:
                return 'Unknown step';
        }
    }
    handleStatement = (stat) => {
        this.setState({ statementInfo: stat, activeStep: this.state.activeStep + 1 });
    }
     
    handleBack = () => { 
        this.setState({ activeStep: this.state.activeStep - 1 });
    }

    handleNext = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
    };
    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };
    handlesetAccept = async () => { 
        this.setState({ accept: false}) 
        window.scrollTo(0, 0)
    }
    handleReset = () => {
        this.setState({ activeStep: 0 })
    };
    render() {
        const { accept, activeStep, steps, classes } = this.state
        return (
            <>
                <div className='auth-block'> 
                    {accept === true ? (
                        <>  
                            <Preamble /> 
                            <div className="accept" >
                                <button   
                                    className="btn btn-primary btn-block"
                                    onClick={this.handlesetAccept}
                                >
                                    თანახმა ვარ{" "}
                                </button>
                            </div>


                        </>) : (<>
                            <div className={classes.root} style={{marginTop: "10px"}} >
                                <Stepper className='no-print' activeStep={activeStep} alternativeLabel >
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper> 
                                <div>
                                    <Typography
                                        component="span"
                                        className={classes.instructions}>
                                        {this.getStepContent(activeStep)}
                                    </Typography> 
                                </div>
                            </div>


                        </>)}
                </div>
            </>
        )

    }
}