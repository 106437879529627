 
import axios from 'axios'
  
 const url = "https://registration.tbsg.ge/back"  
 
 
export const AddNewStatement = async (obj) => {  
    var formData = new FormData();
    formData.append("StatementTypeId", parseInt(obj.contractType));
    formData.append("ContractorTypeId", parseInt(obj.contractorType));
    formData.append("recaptcha", obj.recaptchaResponse);
    formData.append("Note", obj.description);
    formData.append("Address", obj.address);
    formData.append("PhoneNumber", obj.phoneNumber);
    formData.append("Mail", obj.mail);
    formData.append("IdentityNumber",obj.identityCode ? obj.identityCode : '');
    formData.append("OrganizationName", obj.oName ? obj.oName : '');
    formData.append("PrivateNumber", obj.privateNumber ? obj.privateNumber : '');
    formData.append("FullName", obj.firstName + ' ' + obj.lastName);
    formData.append("Number", obj.number);
    if (obj.files) obj.files.map((item) => formData.append("Files", item))
    let result = {}
    await fetch(url + '/api/Statement', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            // 'Content-Type': 'application/json',
            // 'Access-Control-Expose-Headers': '*',
        },
        body: formData,
    })
        .then((response) => response.json()) 
        .then((data) => {   
            if (data.errors) {
                let errors = Object.values(data.errors);
                result = {
                    success: false,
                    errors: errors.map((text) => text[0])
                }
            } 
            else{
                result={
                    success: true,
                    errors: null,
                }
            }
        });

    return result
}

