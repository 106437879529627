import React, { Component } from 'react'
import './App.css'
import { Form, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import RegistrationStatement from '../registrationStatement/RegistrationStatement'
import StatementStepper from "../stepper/StatementStepper"
import Layout from "../layout/Layout"

class App extends Component {

  state = {
    showSideDrawer: true
  }
  render() {
    return (

      <div className='App' id='root'>
        <Layout />
        <StatementStepper />

      </div>
    )
  }
}

export default App
