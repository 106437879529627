import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './components/app/App'; 
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"

 
ReactDOM.render( 
    <Router> 
      <App />
    </Router>,
  document.getElementById('root')
);
 
 
