import React from "react";
import { Loader } from '../loader/Loader'
import { AddNewStatement } from '../../api/Api'
import Swal from 'sweetalert2'


class DocView extends React.Component {
    state = {
        buttonEnabled: this.props.setStatement.buttonEnabled
    }

    backClick = () => {
        this.props.backToreg();
    }
    registerClick = async () => {
        await this.setState({ buttonEnabled: false });
        const res = await AddNewStatement(this.props.setStatement);
        await this.setState({ buttonEnabled: true });
        const { success, errors } = res;
        if (success) {
            Swal.fire(
                {
                    title: 'განცხადება წარმატებით დარეგისტრირდა!',
                    icon: 'success',
                    showConfirmButton: true,
                    timer: 25000
                })
                .then(
                    window.location.assign(
                        'http://tbsg.ge/'
                    ))

        } else {
            let errtext = "";
            if (errors) errors.map((item) => errtext += item + '\n')
            Swal.fire(errtext, '', 'info')
        }
    }

    render() {
        return (
            <>
                <div style={{ marginRight: "100px", marginLeft:"20px" }}>
                    <p align="center">
                        <strong>N</strong>
                    </p>
                    <p align="right">
                        <strong>შ.პ.ს „თბილსერვის ჯგუფი“_ს დირექტორს</strong>
                    </p>
                    <p align="right">
                        <strong>განმცხადებელი: {this.props.setStatement.firstName} {this.props.setStatement.lastName} </strong>
                    </p>
                    <p align="right">
                        <strong>პირ.ნომერი: {this.props.setStatement.privateNumber}</strong>
                    </p>
                    <p align="right">
                        <strong>მისამართი:  {this.props.setStatement.address}</strong>
                    </p>
                    <p align="right">
                        <strong>აბონენტის N: {this.props.setStatement.number} </strong>
                    </p>
                    <p align="right">
                        <strong>ტელეფონი:  {this.props.setStatement.phoneNumber}</strong>
                    </p>
                    {this.props.setStatement.contractorType === '2' || this.props.setStatement.contractorType === '3' ? (
                        <>
                            <p align="right">
                                <strong>საიდენტიფიკაციო კოდი:  {this.props.setStatement.identityCode}</strong>
                            </p>
                            <p align="right">
                                <strong>ორგანიზაციის დასახელება:  {this.props.setStatement.oName}</strong>
                            </p>
                        </>
                    )
                        : <></>

                    }
                    <p align="right">
                        <strong></strong>
                    </p>
                    <p align="center">
                        <strong>გ ა ნ ც ხ ა დ ე ბ ა</strong>
                    </p> 
                    <p align="left" >
                    &nbsp; {this.props.setStatement.description}
                    </p>
                </div>
                <div className='no-print'>
                    <div
                        type="button"
                        className="btn btn-info btn-block"
                        onClick={this.backClick}>
                        უკან დაბრუნება
                    </div>
                    <div
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={this.registerClick}
                    >
                        {!this.state.buttonEnabled ? (
                            <Loader />
                        ) : (
                            <>განცხადების რეგისტრაცია</>
                        )} </div>
                </div>

            </>
        )
    }
}
export default DocView