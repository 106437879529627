

export const Suladobis_koreqtireba = "<p style=text-align:justify;font-size:15px> სულადობის კორექტირების მიზნით მოქალაქემ უნდა წარმოადგინოს სამოქალაქო რეესტრიდან ამონაწერი, "
+"მისამართზე რეგისტრირებული სულთა რაოდენობის შესახებ (საკადასტრო კოდის მითითებით), პირადობის მოწმობა, აბონენტის ნომერი და  ამონაწერი საჯარო "
+"რეესტრიდან. სამოქალაქო რეესტრის მიერ გაცემული  სულადობის პერიოდულობის ცნობით საკადასტრო კოდის მითითებით "
+"(აღნიშნული საკუთრების (უძრავი ქონების) დამისამართების თარიღიდან). (თუ მისამართები იცვლებოდა უძრავ ქონებაზე,"
+"წარმოდგენილი უნდა იქნეს  ცნობა საჯარო რეესტრიდან მისამართების იდენტურობის შესახებ)"
+"<br /><br /> აუცილებელია თელასის ქვითარში, სამოქალაქო რეესტრის მონაცემთა ბაზასა და საჯარო რეესტრის ამონაწერში მითითებული მისამართი იყოს იდენტური."
+"<br />ე.წ იტალიური ეზოს მცხოვრებთა შემთხვევაში სადაც საცხოვრებელი ფართები დამისამართებული არ არის ქ.თბილისის მუნიციპალიტეტის 2015 წლის "
+"14 იანვრის 02.02.27 განკარგულების საფუძველზე უნდა იქნეს წარმოდგენილი ბინათმესაკუთრეთა ამხანაგობის წევრთა ნოტარიულად დამოწმებული კრების ოქმი, სულადობის შესახებ. "
 +"<br /><br />აუცილებელია თელასის ქვითარში, საჯარო რეესტრის ამონაწერში და ბინათმესაკუთრეთა ამხანაგობის კრების ოქმში  მითითებული მისამართი იყოს იდენტური.</p>";


 export const Koreqcia ="<p style=text-align:justify;font-size:15px> ნებისმიერი სახის (ფართსა და საქმიანობის) ცვლილების შემთხვევაში მოსაკრებელში  "
 +"ოდენობის ცვლილების (კორექტირების) შეტანის მიზნით, დაწესებულებები,  "
 +"ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები ვალდებულნი  "
 +"არიან განხორციელებული ცვლილებიდან არაუგვიანეს 30 კალენდარული დღისა, "
 +"აღნიშნულის თაობაზე აცნობონ სამსახხურს (განცხადებით) და წარმოადგინონ "
 +"პირადობა, აბონენტის ნომერი მათ მიერ დაკავებულ ფართში ცვლილების "
 +"დამადასტურებელი დოკუმენტები მიუხედავად შეტყობინების განხორციელების დღისა, "
 +"დაწესებულებები, ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები "
 +"ვალდებულნი არიან მოსაკრებელი გადაიხადონ შეცვლილი მომსახურების მიღების მთელ პერიოდზე.</p>";

 export const registration = "<p style=text-align:justify;font-size:15px>საქმიანობის დაწყებიდან 30 დღის ვადაში ორგანიზაციები ვალდებულნი არიან "
 +"დარეგისტრირდნენ დასუფთავების მოსაკრებლის გადამხდელად. ამისათვს მათ უნდა "
 +"მიმართონ შ.პ.ს „თბილსერვის-ჯგუფს“ განცხადებით. თუ დარიცხვა ხორციელდება წინა "
 +"პერიოდის მდგომარეობით საჭიროა წარმოდგენილ იქნას კონკრეტულ მისამართზე "
 +"საქმიანობის დაწყების დამადასტურებელი დოკუმენტები. მიუხედავად შეტყობინების "
 +"განხორციელების დღისა, დაწესებულებები, ორგანიზაციები, იურიდიული პირები და "
 +"ინდივიდუალური მეწარმეები ვალდებულნი არიან მოსაკრებელი გადაიხადონ "
 +"მომსახურების მიღების მთელ პერიოდზე. </p>"

 export const Shewyveta = "<p style=text-align:justify;font-size:15px> საქმიანობის შეწყვეტის შესახებ არაუგვიანეს 30 კალენდარული დღისა, "
 +"ორგანიზაციებმა შ.პ.ს თბილსერვის-ჯგუფში“ უნდა წარმოადგინონ განცხადება, "
 +"თანდართული (საქმიანობის) შეწყვეტის დამადასტურებელი დოკუმენტებით, რომელიც "
 +"შესაძოა იყოს ამონაწერი საჯარო რეესტრიდან, ამონაწერი სამეწარმეო რეესტრიდან, "
 +"ნოტარიულად დამოწმებული იჯარის/ქირავნობის ხელშეკრულება. სალარო აპარატის EJT "
 +"print ან შემოსავლების სამსახურიდან სალარო აპარატის დეტალური ინფორმაცია. თუ "
 +"აბონენტი ვერ წარმოადგენს საქმიანობის შეწყვეტის დამადასტურებელ რაიმე "
 +"ოფიციალურ დოკუმენტებს დარიცხვის შეწყვეტა განხორციელდება შეტყობინების "
 +"განხორციელების დღიდან. </p>"

 


  

