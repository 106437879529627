import React from 'react' 
import './Layout.css'

const Header = () => { 
  return (
    <>
      <nav className="navbar  navbar-light bg-light"  >
        <img
          alt=""
          src={require('../../static/images/logotop.png').default}
          width="70"
          height="30"
          className="d-inline-block align-top"
        />
        <div className="form-inline my-2 my-lg-0">
          <div className="container">
            <a href="tel:+995322619050 "><img src={require('../../static/images/telephone.png').default} weight="17px" height="17px" />  2619050   </a>
            </div>
        </div>
      </nav>
    </> 
  )
}
export default Header
