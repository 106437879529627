import React from 'react'
import { FileManager, FileUploader } from 'reactjs-file-uploader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faTimes, faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons'
import './fileUploader.css'
import Swal from 'sweetalert2'

export default class Uploader extends React.Component {
  state = {
    files: this.props.files,
  }

  componentDidMount() {
    this.setState({ files: this.props.files || [] })
  }

  uploadFiles = (files) => {
    return files.map(this.uploadFile)
  }

  uploadFile = (file) => {
   
      return (
        <FileUploader
          key={file.key}
          file={file}
          url=''
          formData={{
            file,
            upload_preset: 'public',
            tags: 'vanilla',
          }}
          readFile
        >
          {this.fileProgress}
        </FileUploader>
      ) 
  }

  removeFile = (file) => {
    const fileArray = this.state.files.filter((f) => f.name !== file.name)
    this.setState({ files: fileArray }, () => {
      this.props.getFiles(this.state.files)
    })
  }

  showPreview = (file) => {
    var url = window.URL.createObjectURL(file)
    var a = document.createElement('a')
    a.target = '_blank'
    a.href = url
    // a.download = "filename.jpeg";
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove()
  }

  fileProgress = ({
    /*
        References to the Event objects.
        Initial state is null and each propert gets assigned on Event.
        */
    uploadReady,
    uploadStart,
    uploadProgress,
    uploadComplete,
    downloadStart,
    downloadProgress,
    downloadComplete,
    error,
    abort,
    timeout,

    /*
        The sequential state of the request
        enum {
        uploadReady, uploadStart, uploadProgress, uploadComplete, downloadStart
        downloadStart, downloadProgress, downloadComplete
        }
        */
    requestState,
    /*
        Function references to start / abort request
        */
    startUpload,
    abortRequest,

    /*
        Request Object reference (XMLHttpReqeust)
        */
    request,

    /*
        Response text Object (JSON)
        */
    response,

    /*
        Data of the file being uploaded (if readData props is true)
        */
    fileData,
    file,
  }) => {
    return (
      fileData && (
        <div className='list-group-item file-item'>
          {this.fileRender(file, fileData)}
          <div>
            {file && (
              <span
                onClick={() => this.showPreview(file)}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                {file.name}{' '}
              </span>
            )}
          </div>
          <div>
            {file && (
              <FontAwesomeIcon
                icon={faTimes}
                style={{ color: 'red', cursor: 'pointer' }}
                className='float-right'
                onClick={() => this.removeFile(file)}
              />
            )}
          </div>
        </div>
      )
    )
  }

  fileRender = (file, fileData) => {
    if (
      file.type === 'application/pdf' ||
      file.type === 'image/tiff' ||
      file.type === 'image/tif'
    ) {
      return (
        <FontAwesomeIcon
          icon={faFilePdf}
          size='3x'
          onClick={() => this.showPreview(file)}
          style={{ cursor: 'pointer' }}
        />
      )
    } else if (
      file.type === 'application/msword' ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return (
        <FontAwesomeIcon
          icon={faFileWord}
          size='3x'
          onClick={() => this.showPreview(file)}
          style={{ cursor: 'pointer' }}
        />
      )
    } else {
      return (
        <img
          src={fileData}
          alt='Preview'
          width='50px'
          height='50px'
          onClick={() => this.showPreview(file)}
          style={{ cursor: 'pointer' }}
        />
      )
    }
  }

  _fileUploads = (event) => { 
     if([
      'image/tiff',
      'image/tif',
       'image/png', 
       'image/jpeg',
       'image/jpg',
       'application/pdf',
       'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
       'application/msword'
       ].includes(event.target.files[0].type))
       {
    if (event.target.files[0].size > 2000000) { 
      Swal.fire({
        title: 'ფაილის ზომა არ უნდა აღემატებოდეს 2 Mb ს', 
        icon: 'warning',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false
      }).then(
        function () {}, 
        function (dismiss) {
          if (dismiss === 'timer') { 
          }
        }
      )
    }
    else {
    this.setState(
      {
        files: this.state.files.concat(Array.from(event.target.files)),
      },
      () => this.props.getFiles(this.state.files)
    )
  }}
  else{
    Swal.fire({
      title: 'თქვენ შეგიძლიათ ატვირთოთ მხოლოდ \n PNG, JPEG, Word, PDF \n ფორმატის ფაილები', 
      icon: 'warning', 
    }) 
  } 
  }

  render() {
    return (
      <div className='card'>
        <input
          id='filePicker'
          type='file'
          accept='image/*, application/pdf'
          onChange={(event) => this._fileUploads(event)}
          style={{ visibility: 'hidden', position: 'absolute' }}
        />
        <div
          className='p-2'
          style={{
            backgroundColor: '#f2f2f2',
          }}
        >
          <label className='btn btn-secondary btn-sm' htmlFor='filePicker'>
            აირჩიეთ ფაილი
          </label>
        </div>

        {this.state.files.length > 0 ? (
          <FileManager files={this.state.files}>{this.uploadFiles}</FileManager>
        ) : (
          <div className='empty-files-container'>
            ფაილები ატვირთული არ არის!
          </div>
        )}
        {this.props.errorMessage ? (
          <div className='alert alert-danger text-center'>
            {this.props.errorMessage}
          </div>
        ) : null}
      </div>
    )
  }
}
