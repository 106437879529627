import React from "react";
class Preamble extends React.Component {
    render() {
        return (
            <>
            <div  className="overflow-auto"   style={{ marginLeft: "20px", marginTop: "20px", marginLeft:"20px", height: "400px"}}>
                <br></br>
                <p>
                    სამსახური ხელმძღვანელობს ქალაქ თბილისის მუნიციპალიტეტის საკრებულოს 2014
                    წლის 12 დეკემბერის №17-46 დადგენილებით
</p>
                <p>
                    დასახლებული ტერიტორიის დასუფთავებისათვის მოსაკრებლის გადახდის ინსტრუქცია
                    (შემდგომში – წესი) შემუშავებულია საქართველოს ორგანული კანონის ,,ადგილობრივი
                    თვითმმართველობის კოდექსის“ და ,,ადგილობრივი მოსაკრებლების შესახებ”
                    საქართველოს კანონის საფუძველზე და განსაზღვრავს ქ. თბილისის მუნიციპალიტეტის
                    ტერიტორიაზე საყოფაცხოვრებო ნარჩენების წარმომქმნელთა მიერ დასახლებული
                    ტერიტორიის დასუფთავებისათვის მოსაკრებლის გადახდის წესს.
</p>
                <p>
                    ეს წესი აწესრიგებს ქ. თბილისის მუნიციპალიტეტის ტერიტორიაზე საყოფაცხოვრებო
                    ნარჩენების წარმომქმნელთა მიერ დასახლებული ტერიტორიის დასუფთავების
                    მოსაკრებლის გადახდასთან დაკავშირებულ ურთიერთობებს, განსაზღვრავს მოსაკრებლის
                    განაკვეთის დაწესების, გადახდის წესსა და მოსაკრებლის გადამხდელთა
                    უფლება-მოვალეობებს.
</p>
                <p>
                    დასახლებული ტერიტორიის დასუფთავების მოსაკრებელი (შემდგომში - მოსაკრებელი)
                    წარმოადგენს ქ. თბილისის მუნიციპალიტეტის ადგილობრივი თვითმმართველობის
                    ორგანოს მიერ შესაბამისი უფლებამოსილი პირის (სამსახურის) მეშვეობით
                    დასახლებული ტერიტორიის დასუფთავებისათვის გაწეული მომსახურებისათვის
                    გათვალისწინებულ აუცილებელ გადასახდელს ადგილობრივი თვითმმართველი ერთეულის
                    ბიუჯეტში.
</p>
                <p>
                    ნარჩენების წარმომქმნელი ვალდებულია მოსაკრებელი გადაიხადოს დადგენილ ვადებში.
</p>
                <p>
                    ქ. თბილისის მუნიციპალიტეტის მიერ განსაზღვრული, უფლებამოსილი სამსახურის
                    მეშვეობით, მოსაკრებლის ამოსაღებად, იურიდიულ პირებთან ხელშეკრულების
                    გაფორმების შემთხვევაში, მოსაკრებელი გადაიხდება ამ წესის მიხედვით.
</p>
                <p>
                    მოსაკრებლის გადამხდელი არის ფიზიკური ან/და იურიდიული პირი, ორგანიზაცია,
                    დაწესებულება, რომელიც ქ. თბილისის თვითმმართველი ერთეულის ტერიტორიაზე
                    წარმოქმნის საყოფაცხოვრებო ნარჩენებს.
</p>
                <p>
                    მოსაკრებელის განაკვეთი, წესდება ქ. თბილისის მუნიციპალიტეტის საკრებულოს მიერ
                    ქალაქ თბილისის მუნიციპალიტეტის ტერიტორიის დასუფთავებისათვის კალენდარული
                    წლისათვის საჭირო ხარჯების შესაბამისად.
</p>
                <p>
                    მოსაკრებლების გადახდევინებისათვის პასუხისმგებლობა ეკისრება უფლებამოსილ
                    სამსახურს ან საამისოდ უფლებამოსილ იურიდიულ პირს.
</p>
                <p>
                    თბილისის ტერიტორიაზე მდებარე საცხოვრებელი ბინის/სახლის მესაკუთრე (გარდა
                    ინდივიდუალური მეწარმისა), ვალდებულია რეგისტრაცია გაუკეთოს მის საკუთრებაზე
                    მცხოვრები პირების რაოდენობასა და რაოდენობის ცვლილებას. ასევე მესაკუთრე
                    ფიზიკური პირი რომლის საკუთრებაშიც წარმოიქმნება ნარჩენი ვალდებულია
                    წარმომქმნელთა რაოდენობის ცვლილების შესახებ დაუყოვნებლივ ან/და არაუმეტეს 30
                    კალენდარული დღისა აცნობოს მოსაკრებლების გადახდევინებისათვის პასუხისმგებელ
                    სამსახურს ან ოპერატორს, რომელიც გავლენას მოახდენს გადასახდელი მოსაკრებლის
                    გაანგარიშებაზე.
</p>
                <p>
                    საცხოვრებელი ბინის/სახლის მესაკუთრეები, დაწესებულებები, ორგანიზაციები,
                    იურიდიული პირები და ინდივიდუალური მეწარმეები, ასევე ნარჩენების წარმომქმნელი
                    და/ან მფლობელი პირები ვალდებული არიან სამსახურის ან ოპერატორის მიერ
                    დადგენილ გონივრულ ვადაში, მაგრამ არა უმეტეს 30 კალენდარული დღისა, გასცენ
                    ნარჩენების გატანისათვის საჭირო ნებისმიერი სახის ინფორმაცია, რამაც
                    შესაძლებელია გავლენა იქონიოს სამსახურის ან ოპერატორის მიერ ამ მომსახურების
                    მიწოდების ხარისხზე და მოცულობაზე.
</p>
                <p>
                    დაწესებულებები, ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები
                    ვალდებულნი არიან დადგენილი წესით რეგისტრაციიდან ან შექმნის შესახებ
                    ადმინისტრაციულ-სამართლებრივი აქტის ძალაში შესვლის დღიდან (აგრეთვე
                    რეორგანიზაციის, ლიკვიდაციის, საქმიანობის პროფილის, მისამართის,
                    ადგილმდებარეობის და ნებისმიერი სახის ცვლილების შემთხვევაში) არაუგვიანეს 30
                    კალენდარული დღისა, აღნიშნულის თაობაზე აცნობონ თბილსის დაგვა-დასუფთავებისა
                    და ნარჩენების მართვის მომსახურების განმახორციელებელ სამსახურს ან ოპერატორს.
                    მიუხედავად შეტყობინების განხორციელების დღისა, დაწესებულებები,
                    ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები ვალდებულნი
                    არიან მოსაკრებელი გადაიხადონ მომსახურების მიღების მთელ პერიოდზე.
</p>
                <p>
                    დასუფთავების მოსაკრებლის გადამხდელად დარეგისტრირებისათვის დაწესებულებები,
                    ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები ვალდებულნი
                    არიან წარმოადგინონ კონკრეტულ მისამართზე (ფართზე) მათი ფუნქციონირების
                    დაწყების დამადასტურებელი დოკუმენტები. მიუხედავად შეტყობინების
                    განხორციელების დღისა, დაწესებულებები, ორგანიზაციები, იურიდიული პირები და
                    ინდივიდუალური მეწარმეები ვალდებულნი არიან მოსაკრებელი გადაიხადონ
                    მომსახურების მიღების მთელ პერიოდზე.
</p>
                <p>
                    დასუფთავების მოსაკრებლის გადამხდელად დარეგისტრირებული მომხმარებლების მიერ,
                    ფაქტობრივად დაკავებულ ფართში განხორციელებული ნებისმიერი სახის ცვლილების
                    შემთხვევაში (რამაც შესაძლებელია გავლენა იქონიოს ნარჩენების მართვის
                    მომსახურების განმახორციელებელი სამსახურის ან ოპერატორის მიერ ამ
                    მომსახურების მიწოდების ხარისხზე და მოცულობაზე), მათზე დარიცხულ მოსაკრებელში
                    ცვლილების (კორექტირების) შეტანის მიზნით, დაწესებულებები, ორგანიზაციები,
                    იურიდიული პირები და ინდივიდუალური მეწარმეები ვალდებულნი არიან არაუგვიანეს
                    30 კალენდარული დღისა, აღნიშნულის თაობაზე აცნობონ თბილისის
                    დაგვა-დასუფთავებისა და ნარჩენების მართვის მომსახურების განმახორციელებელ
                    სამსახურს ან ოპერატორს და წარმოადგინონ მათ მიერ დაკავებულ ფართში ცვლილების
                    დამადასტურებელი დოკუმენტები; მიუხედავად შეტყობინების განხორციელების დღისა,
                    დაწესებულებები, ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები
                    ვალდებულნი არიან მოსაკრებელი გადაიხადონ გაზრდილი მომსახურების მიღების მთელ
                    პერიოდზე.
</p>
                <p>
                    დაწესებულებები, ორგანიზაციები, იურიდიული პირები და ინდივიდუალური მეწარმეები
                    ვალდებულნი არიან საქმიანობის შეწყვეტის შესახებ არაუგვიანეს 30 კალენდარული
                    დღისა, აღნიშნულის თაობაზე აცნობონ თბილისის დაგვა-დასუფთავებისა და
                    ნარჩენების მართვის მომსახურების განმახორციელებელ სამსახურს ან ოპერატორს და
                    წარმოადგინონ კონკრეტულ მისამართზე (ფართში) საქმიანობის შეწყვეტის უტყუარი
                    დამადასტურებელი დოკუმენტი (სახელმწიფო ორგანოების მიერ გაცემული დოკუმენტი
                    საგადასახადო ან სხვა კომპეტენტური სახელმწიფო ორგანოების მიერ გამოცემული
                    ადმინისტრაციული სამართლებრივი აქტები). საქმიანობის შეწყვეტის უტყუარი
                    დამადასტურებელი დოკუმენტების წარმოუდგენლობის შემთხვევაში დასუფთავების
                    მოსაკრებლის დარიცხვის შეწყვეტა განხორციელდება თბილისის დაგვა-დასუფთავებისა
                    და ნარჩენების მართვის მომსახურების განმახორციელებელი სამსახურის ან
                    ოპერატორისადმი შეტყობინების განხორციელების დღიდან ან შესაბამისად
                    უფლებამოსილი პირების მიერ ინსპექტირების შედეგად, ობიექტის ფუნქციონირების
                    შეწყვეტის ფაქტის დადასტურების საფუძველზე შედგენილი შემოწმების აქტის
                    თარიღიდან.                
</p> 
</div>
  </>
        )
    }
}
export default Preamble